import React from "react"
import "./FooterAlternate.css"
import socialMedia from "../../../data/socialMedia.json";
import {
  FooterStyle,
  FooterBody,
  MediaLink
} from "../footer/style"
import { ContainerLayout, ButtonDefault } from "../../common"
const FooterAlternate = () =>
{

    return (
        <FooterStyle>
        <ContainerLayout>
          <div className="footer-container">
          {socialMedia.map(({ id, name, url }) => (
          <MediaLink className="el lined-link" href={url} target="_blank" rel="noopener noreferrer" aria-label={`follow us on ${name}`}>
                {name}
            </MediaLink> 
          ))}
          </div>
        </ContainerLayout>
      </FooterStyle>
    )
}

export default FooterAlternate
